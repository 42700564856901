import {
  InputProps as CInputProps,
  Input as CInput,
  Box,
  Text,
  Flex,
} from "@chakra-ui/react"
import { Children } from "react"
import { BiErrorCircle } from "react-icons/bi"

export interface IInputProps extends CInputProps {
  title?: string
  errorMessage?: string
}

export const Input = (props: IInputProps) => {
  const { title, errorMessage } = props

  return (
    <Box>
      {title ? (
        <Text
          textTransform="capitalize"
          fontSize="sm"
          fontFamily="heading"
          letterSpacing={1.2}
          variant="regular"
          color="text.regular"
          mb={1}
        >
          {title}
        </Text>
      ) : (
        ""
      )}

      <CInput color="text.heading" {...props} />

      {errorMessage ? (
        <Flex align="center" mt={1} color="red.400">
          <Box as={BiErrorCircle} />

          <Text ml={1} mt={0}>
            {errorMessage}
          </Text>
        </Flex>
      ) : (
        ""
      )}
    </Box>
  )
}

export interface IInputWrapper {
  title?: string
  errorMessage?: string
  children: React.ReactNode
}

export const InputWrapper = ({
  title,
  errorMessage,
  children,
}: IInputWrapper) => {
  return (
    <Box>
      {title ? (
        <Text
          textTransform="uppercase"
          fontSize="sm"
          fontFamily="heading"
          letterSpacing={1.2}
          variant="regular"
          color="text.regular"
        >
          {title}
        </Text>
      ) : (
        ""
      )}

      {children}

      {errorMessage ? (
        <Flex align="center" mt={1} color="red.400">
          <Box as={BiErrorCircle} />

          <Text ml={1} mt={0}>
            {errorMessage}
          </Text>
        </Flex>
      ) : (
        ""
      )}
    </Box>
  )
}
