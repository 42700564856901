import { IoIosPeople } from "react-icons/io"
import { BiLineChartDown, BiLineChart, BiExit } from "react-icons/bi"
import { AiOutlineAlert } from "react-icons/ai"
import { BiTimer } from "react-icons/bi"
import { MdDashboard } from "react-icons/md"
import { FaMapSigns } from "react-icons/fa"
import { IoIosSettings } from "react-icons/io"
import { TbReportAnalytics } from "react-icons/tb"

export const menu = [
  {
    title: "Dashboard",
    path: "/",
    icon: MdDashboard,
  },
  {
    title: "Masters",
    path: "/masters",
    icon: IoIosPeople,
  },
  {
    title: "Symbol Mapping",
    path: "/symbol-mapping",
    icon: FaMapSigns,
  },
  {
    title: "Report",
    path: "/reports",
    icon: TbReportAnalytics,
  },

  {
    title: "Logout",
    path: "/logout",
    icon: BiExit,
  },
]
