import React, { useState, useEffect } from "react"
import { useQuery, useQueryClient } from "react-query"
import { getAllMasters } from "../../services/main.service"
import Paginator from "../composites/paginator"
import WithAuth from "../hoc/with-auth"
import { LayoutWithSidebar } from "../layouts/layout-with-sidebar.tsx"
import {
  Badge,
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Grid,
  GridItem,
  Button,
} from "@chakra-ui/react"
import _ from "lodash"
import BlockedLoader from "../composites/blocked-loader"
import { AiOutlinePlus, AiFillEye } from "react-icons/ai"
import { Link } from "react-router-dom"
import { AddMaster } from "./add-master"

const captions = ["Login ID", "Direction", "Type", "Status", "Action"]

const DealsPage = () => {
  const [isSetForcedUpdate, setIsForcedUpdate] = useState(false)
  const [year, setyear] = useState("2023")
  const [currentPage, setCurrentpage] = useState(1)

  const [[startDate, endDate], setDateRange] = useState<
    [Date | undefined, Date | undefined]
  >([undefined, undefined])

  const [loginId, setLoginId] = useState("")
  const [symbolStr, setSymbolStr] = useState<string>("")
  const [finalState, setFinalState] = useState<{
    year: string
    loginId: string
    symbolStr: string
    startDate: Date | undefined
    endDate: Date | undefined
  }>({
    year: year,
    loginId: loginId,
    symbolStr: symbolStr,
    startDate: startDate,
    endDate: endDate,
  })

  const updateFinalState = () => {
    setFinalState({
      year: year,
      loginId,
      symbolStr,
      startDate,
      endDate,
    })
  }

  const {
    data,
    isLoading,
    isError,
    isRefetching,
    isFetched,
    isFetchedAfterMount,
    refetch,
  } = useQuery(
    [
      "deals",
      {
        currentPage,
        finalState,
      },
    ],
    () => getAllMasters(),
    {
      refetchOnWindowFocus: true,
      staleTime: 2,
    }
  )

  useEffect(() => {
    if (isFetchedAfterMount) {
      setIsForcedUpdate(true)
    }
  }, [currentPage, year])

  useEffect(() => {
    if (!isFetchedAfterMount) {
      if (!isRefetching) {
        if (isSetForcedUpdate) {
          setIsForcedUpdate(false)
        }
      }
    }
  }, [isRefetching])

  const formattedCSVDATA = () => {
    let _dataArray: any[] = [[...captions]]

    _.forEach(data?.deals?.data ?? [], (_d) => {
      const rowArray = [
        _d?.Time,
        _d?.Login,
        _d?.Action === 0 ? "BUY" : "SELL",
        _d?.Entry === 0 ? "IN" : "OUT",
        _d?._d,
        _d?.Symbol,
        _d?.Volume,
        _d?.Profit,
        _d?.Comment ?? "-",
      ]

      _dataArray.push(rowArray)
    })

    return _dataArray
  }

  return (
    <>
      <Box m={5} textAlign="right">
        <AddMaster />
      </Box>

      <DealsGrid>
        {isLoading ? (
          <Tr>
            <Td colSpan={captions?.length}>
              <BlockedLoader isBlocked={isLoading}>
                <></>
              </BlockedLoader>
            </Td>
          </Tr>
        ) : (
          <>
            {data?.masters?.length ? (
              <>
                {_.map(data?.masters ?? [], (deal) => {
                  return <DealsTableRow key={deal?.id} deal={deal} />
                })}
              </>
            ) : (
              <>
                <Tr>
                  <Td colSpan={captions?.length}>
                    <Box p={4} textAlign="center">
                      <Text color="text.regular">
                        No data found for the selected dates between
                      </Text>
                    </Box>
                  </Td>
                </Tr>
              </>
            )}
          </>
        )}
      </DealsGrid>

      <Box my={4}>
        <Paginator
          currentPage={currentPage}
          totalPages={data?.deals?.last_page ?? 1}
          onChangePage={(_p: number) => setCurrentpage(_p)}
        />
      </Box>
    </>
  )
}

export default DealsPage

const DealsGrid = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Box overflowX="auto">
        <Table color="text.regular">
          <Thead>
            <Tr my=".8rem" ps="0px">
              {captions.map((caption, idx) => {
                return (
                  <Th
                    fontFamily="heading"
                    color="text.heading"
                    key={idx}
                    p={2}
                    px={4}
                  >
                    <Text color="text.heading" minWidth="100%">
                      {caption}
                    </Text>
                  </Th>
                )
              })}
            </Tr>
          </Thead>
          <Tbody>{children}</Tbody>
        </Table>
      </Box>
    </>
  )
}

const DealsTableRow = ({ deal }: { deal: any }) => {
  return (
    <Tr>
      <Td px={4}>
        <Flex align="center" minWidth="100%" flexWrap="nowrap">
          <Text color="text.regular" minWidth="100%">
            {deal?.LoginID}
          </Text>
        </Flex>
      </Td>
      <Td px={4}>
        <Flex align="center" minWidth="100%" flexWrap="nowrap">
          <Text color="text.regular" minWidth="100%">
            {deal?.type}
          </Text>
        </Flex>
      </Td>
      <Td px={4}>
        <Flex align="center" minWidth="100%" flexWrap="nowrap">
          <Text color="text.regular" minWidth="100%">
            {deal?.by}
          </Text>
        </Flex>
      </Td>
      <Td px={4}>
        <Flex align="center" minWidth="100%" flexWrap="nowrap">
          <Text color="text.regular" minWidth="100%">
            {deal?.status === 1 ? (
              <>
                <Badge colorScheme="green">Active</Badge>
              </>
            ) : (
              <Badge colorScheme="red">InActive</Badge>
            )}
          </Text>
        </Flex>
      </Td>

      <Td px={4}>
        <Flex align="right" minWidth="100%" flexWrap="nowrap">
          <Text color="text.regular" minWidth="100%">
            <Link to={`/masters/${deal?.id}`}>
              <Box cursor="pointer" as={AiFillEye} />
            </Link>
          </Text>
        </Flex>
      </Td>
    </Tr>
  )
}
