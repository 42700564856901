import { extendTheme, defineStyleConfig } from "@chakra-ui/react"

const Text = defineStyleConfig({
  baseStyle: {
    color: "red",
  },
  defaultProps: {},
  variants: {
    regular: {
      color: "green",
    },
  },
})

const theme = extendTheme({
  fonts: {
    heading: `'Rubik', sans-serif`,
    body: `'IBM Plex Sans', sans-serif`,
    monospace: `'IBM Plex Sans', sans-serif`,
  },
  colors: {
    page: {
      bg: "#1c222f",
    },
    card: {
      bg: "#283144",
    },
    text: {
      heading: "#d8deea",
      regular: "#a1b0cb",
    },
    components: {
      Text,
    },
  },
})

export default theme
