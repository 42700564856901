import React, { ReactNode, useEffect } from "react"
import { getMe } from "../../services/login.service"
import { useNavigate } from "react-router-dom"

const WithAuth = ({ children }: { children: ReactNode }) => {
  const user = getMe()
  const navigate = useNavigate()

  useEffect(() => {
    if (!user) {
      navigate("/login")
    }
  }, [user])

  return <>{children}</>
}

export default WithAuth
