import React, { ReactNode, useEffect } from "react"
import { getMe } from "../../services/login.service"
import { useNavigate } from "react-router-dom"

const IsLoggedIn = ({ children }: { children: ReactNode }) => {
  const user = getMe()
  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      navigate("/")
    }
  }, [user])

  if (user) return <></>

  return <>{children}</>
}

export default IsLoggedIn
