import React, { useEffect } from "react"
import WithAuth from "../components/hoc/with-auth"
import { LayoutWithSidebar } from "../components/layouts/layout-with-sidebar.tsx"
import { useNavigate } from "react-router-dom"
import { onLogout } from "../services/login.service"

const Logout = () => {
  const navigate = useNavigate()

  useEffect(() => {
    onLogout()
    navigate("/login")
  }, [])

  return (
    <>
      <WithAuth>
        <LayoutWithSidebar pageTitle="" pageDesc=""></LayoutWithSidebar>
      </WithAuth>
    </>
  )
}

export default Logout
