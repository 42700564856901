import React, { useState, useEffect } from "react"
import { useQuery, useQueryClient } from "react-query"
import { getAllMasters, getAllSymbols } from "../../../services/main.service"
import Paginator from "../../composites/paginator"
import WithAuth from "../../hoc/with-auth"
import { LayoutWithSidebar } from "../../layouts/layout-with-sidebar.tsx"
import {
  Badge,
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Grid,
  GridItem,
  Button,
  useDisclosure,
} from "@chakra-ui/react"
import _ from "lodash"
import BlockedLoader from "../../composites/blocked-loader"
import { AddSymbolModal } from "./add-symbol-modal"
import { FaPen } from "react-icons/fa"
import { MdDeleteForever } from "react-icons/md"
import DeleteDialog from "./delete-modal"

const captions = ["#", "Master", "Slave", "Volume Ratio", "Action"]

const SymbolMapPage = () => {
  const [currentPage, setCurrentpage] = useState(1)
  const [editSymbol, setEditSymbol] = useState(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    data,
    isLoading,
    isError,
    isRefetching,
    isFetched,
    isFetchedAfterMount,
    refetch,
  } = useQuery(["symbols", {}], () => getAllSymbols(), {
    refetchOnWindowFocus: true,
  })
  const [deleteSymbol, setDeleteSymbol] = useState(null)

  return (
    <>
      <Box m={5} textAlign="right" color="text.regular">
        <AddSymbolModal
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={() => {
            setEditSymbol(null)
            onClose()
          }}
          symbol={editSymbol}
          onSymbolCreateSuccess={() => {
            refetch()
            onClose()
            setEditSymbol(null)
          }}
        />
      </Box>
      <DealsGrid>
        {isLoading ? (
          <Tr>
            <Td colSpan={captions?.length}>
              <BlockedLoader isBlocked={isLoading}>
                <></>
              </BlockedLoader>
            </Td>
          </Tr>
        ) : (
          <>
            {_.isEmpty(data?.data?.data?.item) ? (
              <Tr>
                <Td colSpan={captions?.length} textAlign="center">
                  No data found..
                </Td>
              </Tr>
            ) : (
              <>
                {_.map(data?.data?.data?.item, (_item) => (
                  <DealsTableRow
                    deal={_item}
                    editSymbol={editSymbol}
                    setEditSymbol={(_s: any) => {
                      setEditSymbol(_s)
                      onOpen()
                    }}
                    setDeleteSymbol={(_s: any) => {
                      setDeleteSymbol(_s)
                    }}
                  />
                ))}
              </>
            )}
          </>
        )}
      </DealsGrid>
      <Box my={4}>
        <Paginator
          currentPage={currentPage}
          totalPages={data?.data?.last_page ?? 1}
          onChangePage={(_p: number) => setCurrentpage(_p)}
        />
      </Box>
      <DeleteDialog
        symbol={deleteSymbol}
        setDeleteSymbol={setDeleteSymbol}
        deleteSucess={() => {
          refetch()
        }}
      />
    </>
  )
}

export default SymbolMapPage

const DealsGrid = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Box overflowX="auto">
        <Table color="text.regular">
          <Thead>
            <Tr my=".8rem" ps="0px">
              {captions.map((caption, idx) => {
                return (
                  <Th
                    fontFamily="heading"
                    color="text.heading"
                    key={idx}
                    p={2}
                    px={4}
                  >
                    <Text color="text.heading" minWidth="100%">
                      {caption}
                    </Text>
                  </Th>
                )
              })}
            </Tr>
          </Thead>
          <Tbody>{children}</Tbody>
        </Table>
      </Box>
    </>
  )
}

const DealsTableRow = ({
  deal,
  setEditSymbol,
  editSymbol,
  setDeleteSymbol,
}: {
  deal: any
  setEditSymbol: any
  editSymbol: any
  setDeleteSymbol: any
}) => {
  return (
    <Tr>
      <Td px={4}>
        <Flex align="center" minWidth="100%" flexWrap="nowrap">
          <Text color="text.regular" minWidth="100%">
            {deal?.id}
          </Text>
        </Flex>
      </Td>
      <Td px={4}>
        <Flex align="center" minWidth="100%" flexWrap="nowrap">
          <Text color="text.regular" minWidth="100%">
            {deal?.master}
          </Text>
        </Flex>
      </Td>
      <Td px={4}>
        <Flex align="center" minWidth="100%" flexWrap="nowrap">
          <Text color="text.regular" minWidth="100%">
            {deal?.slave}
          </Text>
        </Flex>
      </Td>
      <Td px={4}>
        <Flex align="center" minWidth="100%" flexWrap="nowrap">
          <Text color="text.regular" minWidth="100%">
            {deal?.ratio}
          </Text>
        </Flex>
      </Td>

      <Td px={4}>
        <Flex align="center" minWidth="100%" flexWrap="nowrap">
          <Box mr={6}>
            <Box
              as={FaPen}
              color="blue.500"
              cursor="pointer"
              onClick={() => {
                setEditSymbol(deal)
              }}
            />
          </Box>

          <Box mr={6}>
            <Box
              as={MdDeleteForever}
              color="red.500"
              cursor="pointer"
              onClick={() => {
                setDeleteSymbol(deal)
              }}
            />
          </Box>
        </Flex>
      </Td>
    </Tr>
  )
}
