import React, { useEffect } from "react"
import { ChakraProvider } from "@chakra-ui/react"
import theme from "./theme"
import Navigation from "./navigation"
// import QueryProvider from "./query"
import { QueryClient, QueryClientProvider } from "react-query"
import "react-datepicker/dist/react-datepicker.css"
import { app } from "./config"
import socket from "./socket"

// Create a clien
const queryClient = new QueryClient()

function App() {
  useEffect(() => {
    let url = new URL(window.location.href)
    let params = new URLSearchParams(url.search)
    let mt5url = params.get("mt5URL")

    if (mt5url) {
      localStorage.setItem(app.key_base_mt5_url, mt5url)
    }

    socket.connect()

    return () => {
      socket.disconnect()
    }
  }, [])

  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Navigation />
      </QueryClientProvider>
    </ChakraProvider>
  )
}

export default App

