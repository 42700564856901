import axios from "axios"
import { app } from "../config"

const api = axios.create({
  baseURL: app?.base_api_url ?? "",
  headers: {
    "ngrok-skip-browser-warning": true,
  },
})

export default api
