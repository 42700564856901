import { Button, Text, Box, Input } from "@chakra-ui/react"
import WithAuth from "../components/hoc/with-auth"
import { AuthLayout } from "../components/layouts"
import { LayoutWithSidebar } from "../components/layouts/layout-with-sidebar.tsx"
import DealsPage from "../components/pages/deals-page"
import { MasterDetails } from "../components/pages/master-details"
import { useParams } from "react-router-dom"

const MasterDetailsPage = () => {
  const params = useParams()

  return (
    <>
      <WithAuth>
        <LayoutWithSidebar
          pageTitle={`Masters  #${params?.id}`}
          pageDesc="Detail of the masters"
        >
          <MasterDetails id={params?.id ?? ""} />
        </LayoutWithSidebar>
      </WithAuth>
    </>
  )
}

export default MasterDetailsPage
