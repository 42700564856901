const USER_LS_ = "V1_asa_user_tradex"

export const onLogin = (email: string, password: string) => {
  if (email === "admin@syntex.com" && password === "123456") {
    try {
      localStorage.setItem(USER_LS_, email)
      return true
    } catch (error) {}
  }

  return false
}

export const getMe = () => {
  return localStorage.getItem(USER_LS_) ? true : false
}

export const onLogout = () => {
  try {
    localStorage.removeItem(USER_LS_)
    return true
  } catch (error) {}
}
