import React, { useState, useEffect } from "react"
import { useQuery, useQueryClient } from "react-query"
import { getAllMasters } from "../../services/main.service"
import Paginator from "../composites/paginator"
import WithAuth from "../hoc/with-auth"
import { LayoutWithSidebar } from "../layouts/layout-with-sidebar.tsx"
import {
  Badge,
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Grid,
  GridItem,
  Button,
  Skeleton,
} from "@chakra-ui/react"
import _ from "lodash"
import BlockedLoader from "../composites/blocked-loader"
import { AiOutlinePlus, AiFillEye } from "react-icons/ai"
import { Link } from "react-router-dom"
import { AddMaster } from "./add-master"

const captions = ["Login ID", "Direction", "Type", "Status", "Action"]

const EmptyPage = () => {
  return (
    <>
      <Skeleton width="100%" height="640px" />
    </>
  )
}

export default EmptyPage
