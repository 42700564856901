import {
  Button,
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react"
import { AiOutlinePlus } from "react-icons/ai"
import { SymbolMapCreateForm } from "./symbol-map-create-form"

export const AddSymbolModal = ({
  isOpen,
  onClose,
  onOpen,
  symbol,
  onSymbolCreateSuccess,
}: {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  symbol?: any
  onSymbolCreateSuccess: () => void
}) => {
  return (
    <>
      <Button colorScheme="blue" onClick={onOpen}>
        <Box as={AiOutlinePlus} mr={2} />
        Add Symbol Mapping
      </Button>

      <Modal
        isCentered={true}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay />
        <ModalContent bg="page.bg">
          <ModalHeader>
            <Text color="text.heading">
              {symbol ? "Update" : "Add"} Symbol Mapping
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SymbolMapCreateForm
              symbolmap={symbol}
              onSymbolCreateSuccess={onSymbolCreateSuccess}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
