import { useToast } from "@chakra-ui/react"

export const useShowToastHook = () => {
  const toast = useToast()

  const showSuccess = (msg: string) => {
    toast({
      title: "Success",
      description: msg,
      status: "success",
      duration: 4000,
      isClosable: true,
    })
  }

  const showError = (msg: string) => {
    toast({
      title: "Success",
      description: msg,
      status: "error",
      duration: 4000,
      isClosable: true,
    })
  }

  return { showSuccess, showError }
}
