import LoginPage from "../components/pages/login-page"
import Dashboard from "../screens/dashboard"
import Deals from "../screens/deals"
import Logout from "../screens/logout"
import MasterDetailsPage from "../screens/master-details"
import Reports from "../screens/reports"
import Settings from "../screens/settings"
import SymbolMapping from "../screens/symbolmapping"

export const routes = [
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/masters",
    element: <Deals />,
  },

  {
    path: "/masters/:id",
    element: <MasterDetailsPage />,
  },
  {
    path: "/symbol-mapping",
    element: <SymbolMapping />,
  },
  {
    path: "/reports",
    element: <Reports />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "*",
    element: <>404 Page not found</>,
  },
]
