import { AuthLayout } from "../layouts"
import { Grid, Button, Box, Text } from "@chakra-ui/react"
import { Input } from "../composites"
import { Formik, Form, Field, FormikHelpers } from "formik"
import * as Yup from "yup"
import { onLogin } from "../../services/login.service"
import { useShowToastHook } from "../../hooks/show-toast-hook"
import { useNavigate } from "react-router-dom"
import IsLoggedIn from "../hoc/is-logged-in"

const LoginPage = () => {
  return (
    <IsLoggedIn>
      <AuthLayout
        title=" Welcome back!"
        desc="Please sign-in to your account and start the adventure"
      >
        <LoginForm />
      </AuthLayout>
    </IsLoggedIn>
  )
}

export default LoginPage

const SignupSchema = Yup.object().shape({
  password: Yup.string()
    .min(2, "Password is too Short!")
    .max(50, "Password is too Long!")
    .required("Password is required!"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email address is required!"),
})

const LoginForm = () => {
  const { showSuccess, showError } = useShowToastHook()
  const navigate = useNavigate()

  const handleLogin = (values: any, actions: FormikHelpers<any>) => {
    actions.setSubmitting(true)

    setTimeout(() => {
      if (onLogin(values.email, values.password)) {
        showSuccess("Login success redirecting to dashboard...")
        navigate("/")
      } else {
        showError("Invalid email or password combination.")
      }

      actions.setSubmitting(false)
    }, 2500)
  }

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={SignupSchema}
      onSubmit={(values, actions: any) => {
        handleLogin(values, actions)
      }}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <Grid gap={6}>
            <Field name="email">
              {({ field, form, meta }: any) => (
                <Input
                  title="Email Address"
                  {...field}
                  placeholder="Enter email address"
                  errorMessage={meta.touched && meta.error ? meta.error : ""}
                />
              )}
            </Field>

            <Field name="password">
              {({ field, form, meta }: any) => (
                <Box>
                  <Input
                    type="password"
                    title="password"
                    {...field}
                    placeholder="Enter password"
                    errorMessage={meta.touched && meta.error ? meta.error : ""}
                  />
                </Box>
              )}
            </Field>

            <Box width="100%">
              <Button
                isLoading={isSubmitting}
                colorScheme="blue"
                type="submit"
                width="100%"
              >
                Login
              </Button>
              <br />
              <br />
            </Box>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
