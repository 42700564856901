import React, { useState, useEffect } from "react"
import { useQuery, useQueryClient } from "react-query"
import { getAllMasters } from "../../services/main.service"
import Paginator from "../composites/paginator"
import WithAuth from "../hoc/with-auth"
import { LayoutWithSidebar } from "../layouts/layout-with-sidebar.tsx"
import {
  Badge,
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Grid,
  GridItem,
  Button,
  Skeleton,
} from "@chakra-ui/react"
import _ from "lodash"
import BlockedLoader from "../composites/blocked-loader"
import { AiOutlinePlus, AiFillEye } from "react-icons/ai"
import { Link } from "react-router-dom"
import { AddMaster } from "./add-master"
import { MdCandlestickChart } from "react-icons/md"

import { RiUserStarFill } from "react-icons/ri"
import { SiSymbolab } from "react-icons/si"
import { MdAttachMoney } from "react-icons/md"

const captions = ["Login ID", "Direction", "Type", "Status", "Action"]

const DashboardPage = () => {
  return (
    <>
      <Box width="100%">
        <Grid
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
            "repeat(4, 1fr)",
            "repeat(4, 1fr)",
          ]}
          gap={6}
          my={6}
        >
          <CardBox
            title="0"
            desc="Total Open Orders"
            bgColor="#30405f"
            icon={MdCandlestickChart}
            iconColor="#5a8dee"
          />

          <CardBox
            title="0"
            desc="Total Active Masters"
            bgColor="#30405f"
            icon={RiUserStarFill}
            iconColor="#5a8dee"
          />

          <CardBox
            title="0"
            desc="Total Symbols"
            bgColor="#30405f"
            icon={SiSymbolab}
            iconColor="#5a8dee"
          />

          <CardBox
            title="0"
            desc="Total Profit"
            bgColor="#30405f"
            icon={MdAttachMoney}
            iconColor="#5a8dee"
          />
        </Grid>

        <Flex direction="row" align="center" justify="space-between" mb={4}>
          <Box width="calc(70% - 16px)">
            <Skeleton width="100%" height="400px" />
          </Box>

          <Box width="30%">
            <Skeleton width="100%" height="400px" />
          </Box>
        </Flex>

        <Flex direction="row" align="center" justify="space-between">
          <Box width="100%">
            <Skeleton width="100%" height="100" />
          </Box>
        </Flex>
      </Box>
    </>
  )
}

export default DashboardPage

const CardBox = ({
  title,
  desc,
  icon,
  bgColor,
  iconColor,
}: {
  title: string
  desc: string
  icon: any
  bgColor: string
  iconColor: string
}) => {
  return (
    <>
      <Box
        bg="card.bg"
        p={4}
        borderRadius="sm"
        boxShadow="0 0.125rem 0.875rem 0 rgba(0,0,0,.16)"
        borderBottomWidth="2px"
        borderBottomColor={iconColor}
      >
        <Flex direction="row" align="center" mb={4}>
          <Box position="relative" mr={4}>
            <Flex
              align="center"
              justify="center"
              height="38px"
              width="38px"
              bg={bgColor}
            >
              <Box color={iconColor} as={icon} fontSize="xl" />
            </Flex>
          </Box>

          <Text
            fontSize="xl"
            color="text.heading"
            fontWeight="bold"
            fontFamily="heading"
            letterSpacing="2px"
            mb={0}
          >
            {title}
          </Text>
        </Flex>

        <Text color="text.regular" fontSize="sm">
          {desc}
        </Text>
      </Box>
    </>
  )
}
