import React from "react"
import { Layout, ILayout } from "./layout"
import { Flex, Box, Text } from "@chakra-ui/react"
import { Logo } from "../primitives"

export interface IAuthLayout extends ILayout {
  title: React.ReactNode
  desc: React.ReactNode
}

export const AuthLayout = ({ children, title, desc }: IAuthLayout) => {
  return (
    <Flex
      width="100vw"
      height="100vh"
      align="center"
      justify="center"
      background="page.bg"
    >
      <Flex
        direction="column"
        justify="space-evenly"
        mx="auto"
        width="100%"
        maxWidth={["100%", "450px", "450px", "450px"]}
        borderRadius="md"
        boxShadow="0 0.125rem 0.875rem 0 rgba(0, 0, 0, 0.16)"
        //border="1px solid"
        //borderColor="gray.200"
        p={6}
        background="card.bg"
        minHeight="500px"
      >
        <Flex
          align="center"
          justify="center"
          p={4}
          height="30px"
          textAlign="center"
        >
          <Box
            fontSize="4xl"
            fontFamily="heading"
            fontWeight={900}
            color="red"
            letterSpacing="6px"
          >
            <Logo />
          </Box>
        </Flex>

        <Box my={6}>
          <Text fontSize="xl" fontFamily="heading" color="text.heading">
            {title}
          </Text>
          <Text color="text.regular">{desc}</Text>
        </Box>

        {children}
      </Flex>
    </Flex>
  )
}
