import React, { useEffect, useState } from "react"
import { useWindowSize } from "../../../hooks/use-window-size"
import { ILayout, Layout } from "../layout"
import { Flex, Text, Box } from "@chakra-ui/react"
import { Sidebar } from "./sidebar"
import { BsChevronDoubleRight, BsChevronDoubleLeft } from "react-icons/bs"

export interface ILayoutWithSidebar extends ILayout {
  pageTitle: string
  pageDesc: string
}

export const LayoutWithSidebar = (props: ILayoutWithSidebar) => {
  const [hideMenu, setHideMenu] = useState(false)
  const windowSize = useWindowSize()
  const [screenType, setScreenType] = useState<"mobile" | "desktop">("desktop")

  useEffect(() => {
    if (windowSize.width >= 768) {
      setScreenType("desktop")
    } else {
      setScreenType("mobile")
    }
  }, [windowSize.width])

  const getPaddingLeft = () => {
    if (screenType === "desktop") {
      if (!hideMenu) {
        return [0, 0, "260px", "260px"]
      }
    }

    return [0, 0, 0, 0]
  }

  return (
    <Layout>
      <Sidebar screen={screenType} hideMenu={hideMenu} />
      <Flex
        // width="100vw"
        height="100%"
        minHeight="100vh"
        // paddingLeft={getPaddingLeft()}
        position="relative"
        direction="column"
      >
        <Box width="100%" flex={1} p={4}>
          <>
            <Flex align="center">
              <Box>
                <Text
                  color="text.heading"
                  fontSize="xl"
                  fontFamily="heading"
                  lineHeight={1}
                >
                  {props.pageTitle}
                </Text>

                <Text color="text.regular" fontSize="sm">
                  {props.pageDesc}
                </Text>
              </Box>
            </Flex>
          </>

          <Box pt={4}>{props?.children}</Box>
        </Box>
      </Flex>
    </Layout>
  )
}
