import React from "react"
import * as Yup from "yup"
import { Form, Formik } from "formik"
import { Box, Button, Flex, Grid } from "@chakra-ui/react"
import { FormikInput } from "../../composites"
import { el } from "date-fns/locale"
import { createSymbol, updateSymbol } from "../../../services/main.service"
import { useShowToastHook } from "../../../hooks/show-toast-hook"

const SymbolSchema = Yup.object().shape({
  master: Yup.string().trim().required("Master symbol is required"),
  slave: Yup.string().trim().required("Slave symbol is required"),
  ratio: Yup.number().min(0).required("Volume ratio is required"),
})

export const SymbolMapCreateForm = ({
  symbolmap,
  onSymbolCreateSuccess,
}: {
  symbolmap?: any
  onSymbolCreateSuccess?: () => void
}) => {
  const { showSuccess, showError } = useShowToastHook()

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          master: symbolmap?.master ?? "",
          slave: symbolmap?.slave ?? "",
          ratio: symbolmap?.ratio ?? 1,
        }}
        validationSchema={SymbolSchema}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true)
          try {
            if (symbolmap) {
              const result = await updateSymbol({ ...symbolmap, ...values })
              console.log(result)

              if (result?.data?.status) {
                if (onSymbolCreateSuccess) {
                  showSuccess("Symbol updated successfully")
                  onSymbolCreateSuccess()
                }
              } else {
                console.log("FAIELD", result?.data)
              }
            } else {
              const result = await createSymbol(values)

              if (result?.data?.status) {
                if (onSymbolCreateSuccess) {
                  showSuccess("Symbol created successfully")
                  onSymbolCreateSuccess()
                }
              }
            }
          } catch (error) {}

          actions.setSubmitting(false)
        }}
      >
        {({ errors, touched, isSubmitting, values, submitForm }) => (
          <Form>
            <Box width="100%">
              <Grid gap={6}>
                <FormikInput title="Master Symbol" name="master" />

                <FormikInput title="Slave Symbol" name="slave" />

                <FormikInput title="Volume Ratio" name="ratio" type="number" />

                <Flex justify="flex-end">
                  <Button
                    isLoading={isSubmitting}
                    colorScheme="blue"
                    type="submit"
                    onClick={submitForm}
                  >
                    {symbolmap ? "Update" : "Create"} Symbol Mapping
                  </Button>
                </Flex>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
      <br />
    </>
  )
}
