import React from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { routes } from "./config"

const router = createBrowserRouter(routes)

const Navigation = () => {
  return <RouterProvider router={router} />
}

export default Navigation
