import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Button,
} from "@chakra-ui/react"
import { useRef, useState } from "react"
import { useShowToastHook } from "../../../hooks/show-toast-hook"
import { deleteSymbol } from "../../../services/main.service"

const DeleteDialog = ({
  symbol,
  setDeleteSymbol,
  deleteSucess,
}: {
  symbol?: any
  setDeleteSymbol: (_a?: any) => void
  deleteSucess: () => void
}) => {
  const cancelRef = useRef<any>(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const { showError, showSuccess } = useShowToastHook()

  const handleDelete = async () => {
    setIsDeleting(true)
    try {
      const result = await deleteSymbol(symbol?.id)

      console.log(result)

      if (result?.data?.status) {
        showSuccess("Symbol mapping deleted")
        deleteSucess()
        setDeleteSymbol(null)
      }
    } catch (error) {}

    setIsDeleting(false)
  }

  return (
    <>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={symbol ? true : false}
        onClose={() => {
          setDeleteSymbol(null)
        }}
        isCentered={true}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Symbol?
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => {
                  setDeleteSymbol(null)
                }}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                isLoading={isDeleting}
                onClick={handleDelete}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default DeleteDialog
