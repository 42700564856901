import * as React from "react"
import { Flex, Box, Button, Text } from "@chakra-ui/react"
import {
  AiOutlineDoubleLeft,
  AiOutlineLeft,
  AiOutlineDoubleRight,
  AiOutlineRight,
} from "react-icons/ai"

const Paginator = ({
  currentPage,
  totalPages,
  onChangePage,
}: {
  currentPage: number
  totalPages: number
  onChangePage: (_num: number) => void
}) => {
  return (
    <>
      <Box textAlign="right" width="100%">
        <Button
          size="sm"
          borderRadius="md"
          mr={1}
          onClick={() => {
            onChangePage(1)
          }}
        >
          <Box as={AiOutlineDoubleLeft} />
        </Button>
        <Button
          size="sm"
          borderRadius="md"
          mr={1}
          onClick={() => {
            if (currentPage != 1) {
              onChangePage(currentPage - 1)
            }
          }}
        >
          <Box as={AiOutlineLeft} />
        </Button>

        <Button size="sm" borderRadius="md" mr={1} colorScheme="blue">
          {currentPage}
        </Button>

        <Button
          size="sm"
          borderRadius="md"
          mr={1}
          onClick={() => {
            if (currentPage != totalPages) {
              onChangePage(currentPage + 1)
            }
          }}
        >
          <Box as={AiOutlineRight} />
        </Button>

        <Button
          size="sm"
          borderRadius="md"
          mr={1}
          onClick={() => {
            onChangePage(totalPages)
          }}
        >
          <Box as={AiOutlineDoubleRight} />
        </Button>
      </Box>
    </>
  )
}

export default Paginator
