import { Box, Spinner, Flex, Skeleton } from "@chakra-ui/react"
import React from "react"

const BlockedLoader = ({
  children,
  isBlocked,
}: {
  isBlocked?: boolean
  children: React.ReactNode
}) => {
  return (
    <Box position="relative" width="100%" minHeight="600px">
      {children}

      {isBlocked && (
        <LoaderOverlay />
        // <Skeleton
        //   position="absolute"
        //   top={0}
        //   left="0"
        //   height="100%"
        //   width="100%"
        // />
      )}
    </Box>
  )
}

export default BlockedLoader

const LoaderOverlay = () => {
  return (
    <>
      <Flex
        position="absolute"
        top={0}
        left="0"
        height="100%"
        width="100%"
        align="center"
        justify="center"
        bg="rgba(0,0,0,0.6)"
      >
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </Flex>
    </>
  )
}
