import React, { ReactNode } from "react"
import { Box } from "@chakra-ui/react"

export interface ILayout {
  children?: ReactNode
}

export const Layout = ({ children }: ILayout) => {
  return (
    <Box width="100vw" height="100%" minHeight="100vh" background="page.bg">
      <Box width="100%" maxWidth="1400px" mx="auto" paddingTop="100px">
        {children}
      </Box>
    </Box>
  )
}
