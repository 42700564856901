import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from "@chakra-ui/react"
import BasicDetails from "./basic-details"
import { MasterSlaves } from "./slaves"
import { TradingAccountInfo } from "./trading-account-info"
import { OpenPositions } from "./open-positions"
import { BsChevronRight } from "react-icons/bs"
import { Link, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { IMaster } from "../../../models"
import { getMasterById } from "../../../services/main.service"
import BlockedLoader from "../../composites/blocked-loader"
import TradeRequests from "./trade-request"

export const MasterDetails = ({ id }: { id: string }) => {
  const [master, setMaster] = useState<IMaster | null>(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    ;(async () => {
      try {
        const result = await getMasterById(id)

        if (result?.master) {
          setMaster(result?.master)
        } else {
          navigate("/masters")
        }
      } catch (error) {}
      setLoading(false)
    })()
  }, [])

  return (
    <>
      <Box my={6}>
        <Breadcrumb
          spacing="8px"
          separator={<Box as={BsChevronRight} color="text.regular" />}
        >
          <BreadcrumbItem color="text.regular">
            <Link color="text.regular" to="/masters">
              Masters
            </Link>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink color="text.regular"> #{id} </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Box>

      {loading && !master ? (
        <BlockedLoader isBlocked={loading}>
          <></>
        </BlockedLoader>
      ) : (
        <Box bg="card.bg" p={6}>
          <Tabs
            isLazy={true}
            variant="unstyled"
            _selected={{
              color: "text.heading",
              opacity: 1,
              background: "page.bg",
            }}
          >
            <TabList>
              <Tab
                color="text.regular"
                _selected={{
                  color: "text.heading",
                  opacity: 1,
                  background: "page.bg",
                }}
              >
                Basic Details
              </Tab>
              <Tab
                _selected={{
                  color: "text.heading",
                  opacity: 1,
                  background: "page.bg",
                }}
                color="text.regular"
              >
                Trade Requests
              </Tab>
              {/* <Tab>Trading Account Info</Tab>
              <Tab>Open Positions</Tab> */}
            </TabList>

            <TabPanels>
              <TabPanel>
                <BasicDetails master={master!} setMaster={setMaster} />
              </TabPanel>
              <TabPanel>
                <TradeRequests master={master!} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      )}
    </>
  )
}
