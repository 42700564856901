import React, { useState, useEffect, ComponentProps } from "react"
import { useField } from "formik"
import { Input } from "./input"

interface IFormikInputProps extends ComponentProps<typeof Input> {
  name: string
}

export const FormikInput = (props: IFormikInputProps) => {
  const [field, meta, helpers] = useField(props?.name)

  return (
    <Input
      color="text.regular"
      {...props}
      {...field}
      errorMessage={meta.touched && meta.error ? meta.error : ""}
    />
  )
}
