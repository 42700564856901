import React, { useState, useEffect, ComponentProps } from "react"
import { useField } from "formik"
import { Input, InputWrapper } from "./input"
import { ReactSelect } from "./select"

interface IFormikInputProps extends ComponentProps<typeof ReactSelect> {
  name: string
  title?: string
}

export const FormikSelect = (props: IFormikInputProps) => {
  const [field, meta, helpers] = useField(props?.name)

  return (
    <InputWrapper
      title={props?.title}
      errorMessage={meta.touched && meta.error ? meta.error : ""}
    >
      <ReactSelect
        {...props}
        {...field}
        value={field?.value}
        onChange={(e) => {
          helpers.setValue(e)
          helpers.setTouched(true)
        }}
        onMenuOpen={() => {
          helpers.setTouched(true)
        }}
      />
    </InputWrapper>
  )
}
