import { Flex, Text } from "@chakra-ui/react"

export const Logo = () => {
  return (
    <>
      <Flex align="flex-end">
        <Text lineHeight="1" mb={-1} letterSpacing="1px" color="red.400">
          SyntexFX
        </Text>
      </Flex>
    </>
  )
}
