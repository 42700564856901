import { type } from "os"
import App from "../App"
import { app } from "../config"
import api from "./api"

export const getAllMasters = () => {
  return api.get(`/master`).then((res) => res?.data)
}

export const createMaster = (
  _loginId: number,
  type: string,
  by: string,
  status = 1
) => {
  return api
    .post("/master", {
      LoginID: _loginId,
      type: type,
      by: by,
      status: status,
    })
    .then((res) => res?.data)
}

export const updateMaster = (
  id: number,
  _loginId: number,
  type: string,
  by: string,
  status = 1
) => {
  return api
    .put("/master", {
      id: id,
      LoginID: _loginId,
      type: type,
      by: by,
      status: status,
    })
    .then((res) => res?.data)
}

export const getMasterById = (id: string) => {
  return api.get(`/master/${id}`).then((res) => res?.data)
}

export const getTradingAccountInfo = (id: string) => {
  // return {
  //   user: {
  //     loginId: 10060,
  //     balance: 5584.17,
  //     profit: -2561.15,
  //     equity: 3023.02,
  //     margin: 490.11,
  //   },
  // }

  const baseURL = "https://faa2ef2f518f.ngrok.app"

  return api
    .get(`${baseURL}/ManagerApi/GetUser?loginId=${id}`, {
      headers: {
        "ngrok-skip-browser-warning": true,
      },
    })
    .then((res) => res?.data)
}

export const getTradingAccountOpenPositions = (id: string) => {
  // return {
  //   orders: [
  //     {
  //       orderId: "",
  //       symbol: "XAUUSD",
  //       sl: 0,
  //       volume: 500,
  //       volumeExt: 5000000,
  //       id: "",
  //       print: "#72535 sell 0.05 XAUUSD 1916.28",
  //       reason: 16,
  //     },
  //     {
  //       orderId: "",
  //       symbol: "XAUUSD",
  //       sl: 0,
  //       volume: 1000,
  //       volumeExt: 10000000,
  //       id: "",
  //       print: "#74887 sell 0.1 XAUUSD 1933.82",
  //       reason: 16,
  //     },
  //     {
  //       orderId: "",
  //       symbol: "XAUUSD",
  //       sl: 0,
  //       volume: 1000,
  //       volumeExt: 10000000,
  //       id: "",
  //       print: "#75076 sell 0.1 XAUUSD 1943.25",
  //       reason: 16,
  //     },
  //     {
  //       orderId: "",
  //       symbol: "XAUUSD",
  //       sl: 0,
  //       volume: 1500,
  //       volumeExt: 15000000,
  //       id: "",
  //       print: "#75408 sell 0.15 XAUUSD 1973.97",
  //       reason: 16,
  //     },
  //     {
  //       orderId: "",
  //       symbol: "XAUUSD",
  //       sl: 0,
  //       volume: 500,
  //       volumeExt: 5000000,
  //       id: "",
  //       print: "#75455 sell 0.05 XAUUSD 1983.91",
  //       reason: 16,
  //     },
  //     {
  //       orderId: "",
  //       symbol: "XAUUSD",
  //       sl: 0,
  //       volume: 500,
  //       volumeExt: 5000000,
  //       id: "",
  //       print: "#114536 sell 0.05 XAUUSD 1997.72",
  //       reason: 16,
  //     },
  //     {
  //       orderId: "",
  //       symbol: "XAUUSD",
  //       sl: 0,
  //       volume: 500,
  //       volumeExt: 5000000,
  //       id: "",
  //       print: "#114858 sell 0.05 XAUUSD 1991.00",
  //       reason: 16,
  //     },
  //     {
  //       orderId: "",
  //       symbol: "XAUUSD",
  //       sl: 0,
  //       volume: 3000,
  //       volumeExt: 30000000,
  //       id: "",
  //       print: "#122450 buy 0.3 XAUUSD 2048.16",
  //       reason: 16,
  //     },
  //   ],
  // }

  const baseURL = "https://faa2ef2f518f.ngrok.app"
  return api
    .get(`${baseURL}/ManagerApi/GetUserOpenPositions?loginId=${id}`, {
      headers: {
        "ngrok-skip-browser-warning": true,
      },
    })
    .then((res) => res?.data)
}

export const createSlave = (_loginId: any, _masterId: number) => {
  return api
    .post("/slave", {
      LoginID: typeof _loginId === "string" ? parseInt(_loginId) : _loginId,
      MasterID: _masterId,
    })
    .then((res) => res?.data)
}

export const getPositionsByLoginId = (id: string) => {
  return api.get(`/position/id?loginID=${id}`).then((res) => res?.data)
}

export const getAllSymbols = () => {
  return api.get(`/symbolMappingRoute`)
}

export const createSymbol = (data: any) => {
  return api.post(`/symbolMappingRoute`, data)
}

export const updateSymbol = (data: any) => {
  return api.put(`/symbolMappingRoute`, data)
}

export const deleteSymbol = (id: any) => {
  return api.delete(`/symbolMappingRoute?id=${id}`)
}
