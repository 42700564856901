import React, { useEffect, useState } from "react"
import {
  Flex,
  Box,
  Text,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react"
import { Logo } from "../../primitives"
import { menu } from "../../../config"
import _ from "lodash"
import { FaRegHandshake } from "react-icons/fa"
import { IconType } from "react-icons/lib"
import { Link, useLocation, matchRoutes, matchPath } from "react-router-dom"
import { AiOutlineMenu } from "react-icons/ai"

interface ISidebarProps {
  screen: "mobile" | "desktop"
  hideMenu?: boolean
}

export const Sidebar = ({ screen, hideMenu }: ISidebarProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (screen === "desktop") {
      if (isOpen) {
        onClose()
      }
    }
  }, [screen])

  if (screen === "mobile") {
    return (
      <>
        <Flex direction="column" width="100%" p={4}>
          <Flex
            align="center"
            justify="space-between"
            p={4}
            height="30px"
            textAlign="center"
          >
            <Box
              m={4}
              fontSize="4xl"
              fontFamily="heading"
              fontWeight={900}
              color="red"
              letterSpacing="6px"
            >
              <Logo />
            </Box>

            <Box>
              <Box cursor="pointer" onClick={onOpen} as={AiOutlineMenu} />
            </Box>
          </Flex>
        </Flex>

        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Menu</DrawerHeader>

            <DrawerBody>
              <DesktopMenu />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    )
  }

  if (hideMenu) return <></>

  return (
    <>
      <Box width="100%" mx="auto" position="fixed" top={0} left={0} pt={4}>
        <Flex
          direction="row"
          width="100%"
          maxWidth="1400px"
          mx="auto"
          borderBottom="1px solid"
          borderBottomColor="text.regular"
        >
          <Flex
            align="center"
            justify="center"
            p={4}
            height="30px"
            textAlign="center"
          >
            <Box
              m={4}
              fontSize="4xl"
              fontFamily="heading"
              fontWeight={900}
              color="red"
              letterSpacing="6px"
            >
              <Logo />
            </Box>
          </Flex>

          <Box width="100%" flex={1} overflowY="auto" p={4} pt={0} ml="auto">
            <DesktopMenu />
          </Box>
        </Flex>
      </Box>
    </>
  )
}

const DesktopMenu = () => {
  const location = useLocation()

  return (
    <>
      <Flex width="100%" justifyContent="flex-end" gap={4}>
        {_.map(menu, (_m, index) => {
          return (
            <MenuItem
              isActive={matchPath(_m.path, location.pathname) ? true : false}
              {..._m}
            />
          )
        })}
      </Flex>
    </>
  )
}

const MenuItem = ({
  isActive,
  title,
  path,
  icon,
}: {
  isActive?: boolean
  title: string
  path: string
  icon: IconType
}) => {
  return (
    <Link to={path}>
      <Flex
        width="100%"
        p={4}
        py="10px"
        align="center"
        borderRadius="md"
        border="1px solid"
        borderColor="transparent"
        cursor="pointer"
        _hover={{
          opacity: 1,
          color: "text.heading",
        }}
        color={isActive ? "text.heading" : "text.regular"}
        opacity={isActive ? 1 : 0.6}
        background={isActive ? "card.bg" : "transparent"}
      >
        <Box as={icon} fontSize="20px" />
        <Text fontFamily="heading" ml={2}>
          {title}
        </Text>
      </Flex>
    </Link>
  )
}

const MobileMenu = () => {
  return <></>
}
