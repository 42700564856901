import {
  Button,
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react"
import { AiOutlinePlus } from "react-icons/ai"
import { BasicDetailsForm } from "./master-details/basic-details"

export const AddMaster = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button colorScheme="blue" onClick={onOpen}>
        <Box as={AiOutlinePlus} mr={2} />
        Add Master
      </Button>

      <Modal
        isCentered={true}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay />
        <ModalContent bg="page.bg">
          <ModalHeader>
            <Text color="text.heading">Add Master</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <BasicDetailsForm />
            <br />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
