import { Grid, Button, Box, Text, Flex, useToast } from "@chakra-ui/react"
import { FormikSelect, Input } from "../../composites"
import { FormikHelpers, Formik, Field } from "formik"
import { useNavigate, Form } from "react-router-dom"
import { useShowToastHook } from "../../../hooks/show-toast-hook"
import { onLogin } from "../../../services/login.service"
import * as Yup from "yup"
import { FormikInput } from "../../composites/FormikInput"
import {
  createMaster,
  getMasterById,
  updateMaster,
} from "../../../services/main.service"
import { useQuery } from "react-query"
import BlockedLoader from "../../composites/blocked-loader"
import { IMaster } from "../../../models"
import { update } from "lodash"

const BasicDetails = ({
  master,
  setMaster,
}: {
  master: IMaster
  setMaster: (_m: IMaster) => void
}) => {
  return (
    <>
      <BasicDetailsForm master={master ? master : undefined} />
    </>
  )
}

export default BasicDetails

const SignupSchema = Yup.object().shape({
  login: Yup.number()
    .integer("Login ID  should be number only")
    .min(2, "Login ID is too Short!")

    .required("Login ID is required!"),
  direction: Yup.object().default(undefined).required("Direction is required!"),
  action: Yup.object().default(undefined).required("Type is required!"),
  status: Yup.object().default(undefined).required("Status is required!"),
})

export const BasicDetailsForm = ({
  master,
  setMaster,
}: {
  master?: IMaster
  setMaster?: (_m: IMaster) => void
}) => {
  const { showSuccess, showError } = useShowToastHook()
  const navigate = useNavigate()
  const toast = useToast()

  const handleLogin = async (values: any, actions: FormikHelpers<any>) => {
    actions.setSubmitting(true)

    if (master) {
      try {
        const result = await updateMaster(
          master.id,
          master?.LoginID,
          values?.direction?.value,
          values?.action?.value,
          values?.status?.value
        )

        if (result?.success) {
          if (setMaster) {
            setMaster({
              id: master.id,
              LoginID: master?.LoginID,
              type: values?.direction?.value,
              by: values?.action?.value,
              status: values?.status?.value,
            })
          }

          showSuccess("Master udpdated successfully")
        } else {
          showError("Something went wrong")
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        const result = await createMaster(
          parseInt(values?.login),
          values?.direction?.value,
          values?.action?.value,
          values?.status?.value
        )

        if (result?.success) {
          navigate(`/masters/${result?.data?.master[0]?.insertId}`)
          showSuccess("Master created successfully")
        } else {
          showError("Something went wrong")
        }
      } catch (error) {
        console.log(error)
      }
    }

    actions.setSubmitting(false)
  }

  return (
    <Formik
      initialValues={{
        login: master ? master?.LoginID : "",
        direction: master
          ? { label: master?.type, value: master?.type }
          : undefined,
        action: master ? { label: master?.by, value: master?.by } : undefined,
        status: master
          ? master.status === 1
            ? { label: "Active", value: 1 }
            : { label: "Inactive", value: 0 }
          : { label: "Active", value: 1 },
      }}
      validationSchema={SignupSchema}
      onSubmit={(values, actions: any) => {
        console.log(values)
        handleLogin(values, actions)
      }}
    >
      {({ errors, touched, isSubmitting, values, submitForm }) => (
        <Form>
          <Box width="100%">
            <Grid gap={6}>
              <FormikInput
                title="Login Id"
                name="login"
                isReadOnly={master ? true : false}
                isDisabled={master ? true : false}
              />
              <FormikSelect
                title="Direction"
                name="direction"
                options={[
                  { label: "SAME", value: "SAME" },
                  { label: "REVERSE", value: "REVERSE" },
                ]}
              />
              <FormikSelect
                title="Action"
                name="action"
                options={[
                  { label: "EQUITY", value: "EQUITY" },
                  { label: "VOLUME", value: "VOLUME" },
                  { label: "MARGIN", value: "MARGIN" },
                ]}
              />

              <FormikSelect
                title="Status"
                name="status"
                options={[
                  { label: "Active", value: 1 },
                  { label: "Inactive", value: 0 },
                ]}
              />

              <Flex justify="flex-end">
                <Button
                  isLoading={isSubmitting}
                  colorScheme="blue"
                  type="submit"
                  onClick={submitForm}
                >
                  {master ? "Update" : "Create"} &nbsp;Master
                </Button>
              </Flex>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
