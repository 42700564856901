import { Button, Text, Box, Input } from "@chakra-ui/react"
import WithAuth from "../components/hoc/with-auth"
import { AuthLayout } from "../components/layouts"
import { LayoutWithSidebar } from "../components/layouts/layout-with-sidebar.tsx"
import DealsPage from "../components/pages/deals-page"
import { useParams } from "react-router-dom"
import DashboardPage from "../components/pages/dashboard"
import EmptyPage from "../components/pages/EmptyPage"
import TradeRequestsPage from "../components/pages/master-details/trade-request"

const Reports = () => {
  const params = useParams()

  return (
    <>
      <WithAuth>
        <LayoutWithSidebar
          pageTitle={`Trade Reports `}
          pageDesc="List of all the trades"
        >
          <EmptyPage />
        </LayoutWithSidebar>
      </WithAuth>
    </>
  )
}

export default Reports
